import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainScren from "../screens";
import ExampleView from "../screens/exampleView";
import LandingPage from "../screens/landingPage";

const Navigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/profile-details" element={<MainScren />} />
        <Route path="/wooftagofficial" element={<ExampleView />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
